export default {
  common: {
    invalid_form: {
      title: 'An error has occurred',
      message: 'Please check the content of the page',
    },
  },
  new_idea: {
    success: {
      title: 'New idea saved',
      message: 'The idea has been published and is visible on the application.',
    },
    error: {
      title: 'An error occured',
      message: 'Please check the content of the page',
    },
  },
  create_thematic: {
    success: {
      title: 'New thematic created',
      message: 'The thematic is available in the application.',
    },
    error: {
      title: 'An error occured',
      message: 'Please check the content of the page',
    },
  },
  update_thematic: {
    success: {
      title: 'Thematic updated',
      message: 'The thematic has been updated and is visible on the application.',
    },
    error: {
      title: 'An error occured',
      message: 'Please check the content of the page',
    },
  },
  get_thematics: {
    error: {
      title: 'An error occured',
      message: 'Unable to retrieve thematics',
    },
  },
  duplicate_thematic: {
    success: {
      title: 'Thematic duplicated',
      message: 'The thematic has been duplicated and is visible on the application.',
    },
    error: {
      title: 'An error occured',
      message: 'Unable to duplicate the thematic',
    },
  },
  delete_thematic: {
    success: {
      title: 'Thematic deleted',
      message: "The thematic has been deleted and isn't available in the application.",
    },
    error: {
      title: 'An error occured',
      message: 'Unable to delete the thematic',
    },
  },
  sidebar_components: {
    success: {
      title: '{type} has been updated',
      message: 'The changes have been successfully saved',
    },
    error: {
      title: 'An error has occurred',
      message: '{type} has not been updated',
    },
  },
  media_get: {
    success: {
      title: 'Image retrieved',
      message: 'The image has been retrieved.',
    },
    error: {
      title: 'An error has occurred',
      message: "The image couldn't be retrieved",
    },
  },
  media_upload: {
    success: {
      title: 'Image imported',
      message: 'The image has been imported and is visible on the application.',
    },
    error: {
      title: 'An error has occurred',
      message: "The image hasn't been imported",
    },
  },
  media_delete: {
    success: {
      title: 'Image deleted',
      message: 'The image has been deleted.',
    },
    error: {
      title: 'An error has occurred',
      message: "The image hasn't been deleted",
    },
  },
  consultations: {
    save_settings: {
      success: {
        title: 'Settings saved',
        message: 'The settings have been saved.',
      },
      error: {
        title: 'Impossible to save the settings',
        message: 'An error has occurred, the settings have not been saved.',
      },
    },
    generate_Iframe: {
      success: {
        title: 'Successfully copied',
        message: 'The Iframe code has been generated.',
      },
    },
  },
  messages: {
    new_message: {
      success: {
        title: 'Message sent',
        message: 'Your message has been sent successfully.',
      },
      error: {
        title: 'Impossible to send the message',
        message: 'An error has occurred, your message has not been sent.',
      },
    },
    delete_message: {
      success: {
        title: 'Message deleted',
        message: 'Your message has been deleted.',
      },
      error: {
        title: 'Impossible to delete the message',
        message: "An error has occurred, your message hasn't been deleted.",
      },
    },
  },
  notifications: {
    common: {
      error: {
        title: 'An error has occurred',
        message: 'Please check the content of the page',
      },
    },
    notification_sent: {
      success: {
        title: 'Notification sent',
        message: 'The notification has been sent',
      },
      error: {
        title: 'Unable to send the notification',
        message: "An error has occurred, the notification hasn't been sent",
      },
    },
    notification_scheduled: {
      success: {
        title: 'Notification scheduled',
        message: 'The notification has been scheduled.',
      },
      error: {
        title: 'Unable to schedule notification',
        message: 'An error has occurred, your notification has not been programmed.',
      },
    },
    notification_scheduled_recurrent: {
      success: {
        title: 'Notification scheduled',
        message: 'The notification has been registered and will be sent according to its programming.',
      },
      error: {
        title: 'Unable to schedule notification',
        message: 'An error has occurred, your notification has not been programmed.',
      },
    },
    save_notification: {
      success: {
        title: 'Notification updated',
        message: 'The notification has been updated and will be sent according to its schedule.',
      },
      error: {
        title: 'Unable to update notification',
        message: "An error has occurred, the notification hasn't been updated.",
      },
    },
    save_notifications: {
      success: {
        title: 'Notifications updated',
        message: 'The notifications have been updated and will be sent according to their schedule.',
      },
      error: {
        title: 'Unable to update notifications',
        message: "An error has occurred, the notifications haven't been updated",
      },
    },
    delete_notification: {
      success: {
        title: 'Notification deleted | Notifications deleted',
        message: 'The notification has been deleted. | The notifications have been deleted.',
      },
      error: {
        title: 'Impossible to delete the notification | Impossible to delete the notifications',
        message: "An error has occurred, the notification hasn't been deleted. | An error has occurred, the notifications haven't been deleted.",
      },
    },
    duplicate_notification: {
      success: {
        title: 'Notification duplicated',
        message: 'The notification has been duplicated and will be sent according to its schedule.',
      },
      error: {
        title: 'Unable to duplicate notification',
        message: "An error has occurred, the notification hasn't been duplicated.",
      },
    },
    cancel_notification: {
      success: {
        title: 'Notification cancelled | Notifications cancelled',
        message: 'The notification has been cancelled. | The notifications have been cancelled.',
      },
      error: {
        title: 'Impossible to cancel the notification | Impossible to cancel the notifications',
        message: "An error has occurred, the notification hasn't been cancelled. | An error has occurred, the notifications haven't been cancelled.",
      },
    },
    hide_notification: {
      success: {
        title: 'Notification hidden',
        message: "The notification has been hidden and won't be displayed again.",
      },
      error: {
        title: 'Impossible to hide the notification',
        message: "An error has occurred, the notification hasn't been hidden.",
      },
    },
    show_notification: {
      success: {
        title: 'Notification displayed',
        message: 'The notification has been displayed and will be displayed again.',
      },
      error: {
        title: 'Impossible to display the notification',
        message: "An error has occurred, the notification hasn't been displayed.",
      },
    },
    dev_notification: {
      error: {
        title: 'Impossible to send the notification',
        message: 'Test notifications are disabled for this client.',
      },
    },
    update_audience: {
      error: {
        title: 'Unable to recover audience',
        message: 'An error has occurred, the audience could not be retrieved for this notification.',
      },
    },
    no_audience: {
      error: {
        title: 'Your audience is empty',
        message: 'You must select at least one category of subscribers containing users.',
      },
    },
    search_notification: {
      error: {
        title: 'An error has occurred while searching for notifications',
        message: 'An error has occurred, please try again later or contact the support.',
      },
    },
  },
  infrastructures: {
    create_category: {
      success: {
        title: 'Category added.',
        message: 'The category has been added.',
      },
      error: {
        title: 'An error has occurred',
        message: 'Please check the content of the category',
      },
    },
    create_infrastructure: {
      success: {
        title: 'Infrastructures added.',
        message: 'The infrastructure has been successfully added.',
      },
      error: {
        title: 'An error has occurred',
        message: 'Please check the page content.',
      },
    },
    edit_infrastructure: {
      success: {
        title: 'Infrastructure modified.',
        message: 'The infrastructure has been successfully modified.',
      },
      error: {
        title: 'An error has occurred',
        message: "The infrastructure hasn't been modified.",
      },
    },
    add_infrastructures: {
      success: {
        title: 'Infrastructure added. | Infrastructures added.',
        message: 'The infrastructure has been successfully added. | The infrastructures have been successfully added.',
      },
      error: {
        title: 'An error has occurred',
        message: 'The infrastructure was not added. | The infrastructures were not added.',
      },
    },
    import_infrastructure_csv: {
      errorTypeInfra: {
        message: 'Please check the type/category of imported infrastructures',
      },
    },
    search_infrastructures: {
      error: {
        title: 'An error has occurred while searching for infrastructures',
        message: 'An error has occurred, please try again later or contact the support.',
      },
    },
    get_infrastructures: {
      error: {
        title: 'An error has occurred while fetching the infrastructures',
        message: 'Please try again later or contact the support.',
      },
    },
    get_infrastructure_google: {
      error: {
        title: 'An error has occurred while fetching the infrastructure',
        message: 'Error',
      },
    },
    delete_infrastructure: {
      success: {
        title: 'Infrastructure deleted.',
        message: "The infrastructure has been deleted and isn't visible in the application.",
      },
      error: {
        title: 'Unable to delete the infrastructure',
        message: "An error has occurred, the infrastructure hasn't been deleted.",
      },
    },
  },
  garbage: {
    collections: {
      collectionCreate: {
        success: {
          title: 'Collection created',
          message: 'The collection has been created and is now visible in the application',
        },
        error: {
          title: 'Unable to create the collection',
          message: "An error has occurred, the collection hasn't been created.",
        },
      },
      collectionEdit: {
        success: {
          title: 'Collection modified',
          message: 'The collection has been modified and is now visible in the application',
        },
        error: {
          title: 'Unable to modify the collection',
          message: "An error has occurred, the collection hasn't been modified.",
        },
      },
      collectionDelete: {
        success: {
          title: 'Collection deleted',
          message: "The collection has been deleted and isn't visible in the application",
        },
        error: {
          title: 'Unable to delete the collection',
          message: "An error has occurred, the collection hasn't been deleted.",
        },
      },
      collectionDateEdit: {
        success: {
          title: 'Collection date modified',
          message: 'The collection date has been modified and is now visible in the application',
        },
        error: {
          title: 'Unable to modify the collection date',
          message: "An error has occurred, the collection date hasn't been modified.",
        },
      },
      collectionDateDelete: {
        success: {
          title: 'Collection date deleted',
          message: "The collection date has been deleted and isn't visible in the application.",
        },
        error: {
          title: 'Unable to delete the collection date',
          message: "An error has occurred, the collection date hasn't been deleted.",
        },
      },
    },
    sortingGuide: {
      saveSortingGuide: {
        success: {
          title: 'Sorting guide saved',
          message: 'The sorting guide has been saved.',
        },
        error: {
          title: 'Impossible to save the sorting guide',
          message: 'An error has occurred, the sorting guide has not been saved.',
        },
      },
    },
    slots: {
      delete_slot: {
        success: {
          title: 'Slot deleted.',
          message: 'The slot has been removed.',
        },
        error: {
          title: 'The slot could not be removed.',
          message: 'Please try again later or contact support.',
        },
      },
      modify_slot: {
        success: {
          title: 'Slot modified.',
          message: 'The slot has been modified.',
        },
        error: {
          title: 'The slot could not be modified.',
          message: 'Please try again later or contact support.',
        },
      },
      add_slot: {
        success: {
          title: 'Slot added.',
          message: 'The slot has been successfully added.',
        },
        error: {
          title: 'The slot could not be added',
          message: 'Please try again later or contact support.',
        },
      },
      get_template_slots: {
        error: {
          title: 'Unable to retrieve templates',
          message: 'Please try again later or contact support.',
        },
      },
      fill_previous_slot: {
        error: {
          title: 'Error',
          message: 'Please fill in the current slot before creating a new one.',
        },
      },
      delete_date: {
        error: {
          title: 'Unable to delete date {publishDate}',
          message: 'Please try again later or contact support.',
        },
      },
      get_slots_dates: {
        error: {
          title: 'Error, Unable to find the dates associated with the slot',
          message: 'Please try again later or contact support.',
        },
      },
    },
    garbageTypes: {
      createType: {
        success: {
          title: 'Garbage type created',
          message: 'The garbage type has been created.',
        },
        error: {
          title: 'Impossible to create the garbage type',
          message: "An error has occurred, the garbage type hasn't been created.",
        },
      },
      editType: {
        success: {
          title: 'Garbage type modified',
          message: 'The garbage type has been modified.',
        },
        error: {
          title: 'Impossible to modify the garbage type',
          message: "An error has occurred, the garbage type hasn't been modified.",
        },
      },
      deleteType: {
        success: {
          title: 'Garbage type deleted',
          message: 'The garbage type has been deleted.',
        },
        error: {
          title: 'Impossible to delete the garbage type',
          message: "An error has occurred, the garbage type hasn't been deleted.",
        },
      },
      patchColor: {
        success: {
          title: 'Color modified',
          message: 'The color has been modified.',
        },
        error: {
          title: 'Impossible to modify the color',
          message: "An error has occurred, the color hasn't been modified.",
        },
      },
      patchIcon: {
        success: {
          title: 'Icon modified',
          message: 'The icon has been modified.',
        },
        error: {
          title: 'Impossible to modify the icon',
          message: "An error has occurred, the icon hasn't been modified.",
        },
      },
    },
  },
  zones: {
    common: {
      error: {
        title: 'An error has occurred',
        message: 'Please check the content of the page',
      },
    },
    createZone: {
      success: {
        title: 'Zone added',
        message: 'The zone has been added.',
      },
      error: {
        title: 'Impossible to create the zone',
        message: "An error has occurred, the zone hasn't been created.",
      },
    },
    editZone: {
      success: {
        title: 'Zone modified',
        message: 'The zone has been modified.',
      },
      error: {
        title: 'Impossible to modify the zone',
        message: "An error has occurred, the zone hasn't been modified.",
      },
    },
    patchColor: {
      success: {
        title: 'Color modified',
        message: 'The zone color has been modified',
      },
      error: {
        title: 'Impossible to modify the color',
        message: "An error has occurred, the zone color hasn't been modified.",
      },
    },
    deleteZone: {
      success: {
        title: 'Zone deleted',
        message: 'The zone has been deleted.',
      },
      error: {
        title: 'Impossible to delete the zone',
        message: "An error has occurred, the zone hasn't been deleted.",
      },
    },
    zonesOverlap: {
      error: {
        title: 'Zone overlap',
        message: "Please edit your zone so that it doesn't overlap with another zone.",
      },
    },
  },
  citiesManagement: {
    createCity: {
      error: {
        title: 'Impossible to create the city',
        message: 'An error has occurred: the city has not been created.',
      },
    },
    editCity: {
      error: {
        title: 'Impossible to modify the city',
        message: 'An error has occurred, the city has not been modified.',
      },
    },
    deleteCity: {
      error: {
        title: 'Impossible to delete city',
        message: 'An error has occurred: the city has not been deleted.',
      },
    },
    getCities: {
      error: {
        title: 'Unable to retrieve cities',
        message: 'An error has occurred: the cities have not been retrieved.',
      },
    },
    getCity: {
      error: {
        title: 'Unable to retrieve the city',
        message: 'An error has occurred: the city has not been retrieved.',
      },
    },
    saveCities: {
      success: {
        title: 'Cities registered',
        message: 'The changes have been saved.',
      },
      error: {
        title: 'Unable to save cities',
        message: 'The changes could not be saved.',
      },
    },
  },
};
