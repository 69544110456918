export default {
  navigation: {
    myAccount: 'Mon compte',
    myInformation: 'Mes informations',
    preferences: 'Préférences',
    notifications: 'Notifications',
    plan: 'Abonnement',
    rolesAndUsers: 'Rôles et utilisateurs',
    users: 'Utilisateurs',
    rolesManagement: 'Gestion des rôles',
    citiesManagement: 'Gestion des villes',
    appManagement: "Gestion de l'application",
    tilesManagement: 'Gestion des tuiles',
    tree: 'Arborescence',
    stores: 'Fiches stores',
    legal: 'Légal',
    tutorials: 'Tutoriels et guides',
  },
  citiesManagement: {
    toast:
      'Retrouvez l’ensemble des communes de votre territoire.<br> Vous pouvez les renommer, personnaliser leurs étiquettes ou en créer de nouvelles.',
    titleTerritory: 'Mon territoire',
    titleCities: 'Mes communes',
    forms: {
      label: {
        name: 'Nom',
        color: 'Couleur',
      },
      placeholder: {
        name: 'Ville',
        color: 'Choisir une couleur',
      },
      errors: {
        name: 'Veuillez entrer un nom de ville valide',
        colorRequired: 'Veuillez séléctionner une couleur',
        nameAlreadyExists: 'Ce nom de commune est déjà utilisé. Veuillez entrer un nom unique.',
      },
    },
    addCity: 'Ajouter une ville',
    deleteCityModal: {
      title: 'Supprimer une commune',
      warningTitle: 'Êtes-vous sûr(e) de vouloir supprimer cette commune ?',
      warningText: 'La suppression est irréversible. Les utilisateurs de cette commune n’auront plus accès à l’interface d’administration.',
    },
    empty: {
      noCity: 'Aucune ville pour le moment.',
    },
  },
};
