export default {
  common: {
    invalid_form: {
      title: 'Une erreur est survenue',
      message: 'Veuillez vérifier le contenu de la page',
    },
  },
  new_idea: {
    success: {
      title: 'Nouvelle idée enregistrée',
      message: "L'idée a été publiée et est visible sur l'application.",
    },
    error: {
      title: 'Une erreur est survenue',
      message: 'Veuillez vérifier le contenu de la page',
    },
  },
  create_thematic: {
    success: {
      title: 'Nouvelle thématique créée',
      message: "La thématique est disponible dans l'application.",
    },
    error: {
      title: 'Une erreur est survenue',
      message: 'Veuillez vérifier le contenu de la page',
    },
  },
  update_thematic: {
    success: {
      title: 'La thématique a été mise à jour',
      message: "La thématique a été mise à jour et est visible sur l'application.",
    },
    error: {
      title: 'Une erreur est survenue',
      message: 'Veuillez vérifier le contenu de la page',
    },
  },
  get_thematics: {
    error: {
      title: 'Une erreur est survenue',
      message: 'Impossible de récupérer les thématiques',
    },
  },
  duplicate_thematic: {
    success: {
      title: 'La thématique a été dupliquée',
      message: 'La thématique a été dupliquée et est visible sur l’application.',
    },
    error: {
      title: 'Une erreur est survenue',
      message: 'Impossible de dupliquer la thématique',
    },
  },
  delete_thematic: {
    success: {
      title: 'La thématique a été supprimée',
      message: "La thématique a été supprimée et n'est plus disponible dans l'application.",
    },
    error: {
      title: 'Une erreur est survenue',
      message: 'Impossible de supprimer la thématique',
    },
  },
  sidebar_components: {
    success: {
      title: '{type} a été mis à jour | {type} a été mise à jour',
      message: 'Les modifications ont bien été enregistrées',
    },
    error: {
      title: 'Une erreur est survenue',
      message: "{type} n'a pas été mis à jour | {type} n'a pas été mise à jour",
    },
  },
  media_get: {
    success: {
      title: 'Image récupérée',
      message: "L'image a bien été récupérée.",
    },
    error: {
      title: 'Une erreur est survenue',
      message: "L'image n'a pas pu être récupérée",
    },
  },
  media_upload: {
    success: {
      title: 'Image importée',
      message: "L'image a été importée et est visible sur l'application.",
    },
    error: {
      title: 'Une erreur est survenue',
      message: "L'image n'a pas été importée",
    },
  },
  media_delete: {
    success: {
      title: 'Image supprimée',
      message: "L'image a été supprimée.",
    },
    error: {
      title: 'Une erreur est survenue',
      message: "L'image n'a pas été supprimée",
    },
  },
  consultations: {
    save_settings: {
      success: {
        title: 'Paramètres enregistrés',
        message: 'Les paramètres ont été enregistrés.',
      },
      error: {
        title: "Impossible d'enregistrer les paramètres",
        message: 'Veuillez réessayer utlérieurement.',
      },
    },
    generate_Iframe: {
      success: {
        title: 'Copié avec succès',
        message: 'Le code de l’Iframe a bien été généré.',
      },
    },
  },
  messages: {
    new_message: {
      success: {
        title: 'Message envoyé',
        message: 'Votre message a été envoyé avec succès.',
      },
      error: {
        title: "Impossible d'envoyer le message",
        message: "Une erreur est survenue, votre message n'a pas été envoyé.",
      },
    },
    delete_message: {
      success: {
        title: 'Message supprimé',
        message: 'Votre message a été supprimé.',
      },
      error: {
        title: 'Impossible de supprimer le message',
        message: "Une erreur est survenue, votre message n'a pas été supprimé.",
      },
    },
  },
  notifications: {
    common: {
      error: {
        title: 'Une erreur est survenue',
        message: 'Veuillez vérifier le contenu de la page',
      },
    },
    notification_sent: {
      success: {
        title: 'Notification envoyée',
        message: 'La notification a bien été envoyée.',
      },
      error: {
        title: "Impossible d'envoyer la notification",
        message: "Une erreur est survenue, votre notification n'a pas été envoyée.",
      },
    },
    notification_scheduled: {
      success: {
        title: 'Notification programmée',
        message: 'La notification a bien été programmée.',
      },
      error: {
        title: 'Impossible de programmer la notification',
        message: "Une erreur est survenue, votre notification n'a pas été programmée.",
      },
    },
    notification_scheduled_recurrent: {
      success: {
        title: 'Notification programmée',
        message: 'La notification a été enregistrée et sera envoyée en fonction de sa programmation.',
      },
      error: {
        title: 'Impossible de programmer la notification',
        message: "Une erreur est survenue, votre notification n'a pas été programmée.",
      },
    },
    save_notification: {
      success: {
        title: 'Notification enregistrée',
        message: 'La notification a été enregistrée.',
      },
      error: {
        title: 'Impossible d’enregistrer la notification',
        message: "Une erreur est survenue, votre notification n'a pas été enregistrée.",
      },
    },
    save_notifications: {
      success: {
        title: 'Notifications enregistrées',
        message: 'Les notifications ont été enregistrées.',
      },
      error: {
        title: 'Impossible d’enregistrer les notifications',
        message: "Une erreur est survenue, les notifications n'ont pas été enregistrées.",
      },
    },
    delete_notification: {
      success: {
        title: 'Notification supprimée | Notifications supprimées',
        message: 'Votre notification a été supprimée. | Vos notifications ont été supprimées.',
      },
      error: {
        title: 'Impossible de supprimer la notification | Impossible de supprimer les notifications',
        message:
          "Une erreur est survenue, votre notification n'a pas été supprimée. | Une erreur est survenue, vos notifications n'ont pas été supprimées.",
      },
    },
    duplicate_notification: {
      success: {
        title: 'Notification dupliquée',
        message: 'Votre notification a été dupliquée.',
      },
      error: {
        title: 'Impossible de dupliquer la notification',
        message: "Une erreur est survenue, votre notification n'a pas été dupliquée.",
      },
    },
    cancel_notification: {
      success: {
        title: 'Notification annulée | Notifications annulées',
        message: 'Votre notification a été annulée. | Vos notifications ont été annulées.',
      },
      error: {
        title: "Impossible d'annuler la notification | Impossible d'annuler les notifications",
        message:
          "Une erreur est survenue, votre notification n'a pas été annulée. | Une erreur est survenue, vos notifications n'ont pas été annulées.",
      },
    },
    hide_notification: {
      success: {
        title: 'Notification masquée',
        message: "Votre notification a été masquée. Elle n'apparait plus dans l'application.",
      },
      error: {
        title: 'Impossible de masquer la notification',
        message: "Une erreur est survenue, votre notification n'a pas été masquée.",
      },
    },
    show_notification: {
      success: {
        title: 'Notification affichée',
        message: "Votre notification a été affichée. Elle apparait maintenant dans l'application.",
      },
      error: {
        title: 'Impossible d’afficher la notification',
        message: "Une erreur est survenue, votre notification n'a pas été affichée.",
      },
    },
    dev_notification: {
      error: {
        title: 'Impossible d’envoyer la notification',
        message: 'Les notifications de test ne sont pas autorisées pour ce client.',
      },
    },
    update_audience: {
      error: {
        title: "Impossible de récupérer l'audience",
        message: "Une erreur est survenue, l'audience n'a pas pu être récupérée pour cette notification.",
      },
    },
    no_audience: {
      error: {
        title: 'Votre audience est vide',
        message: "Vous devez sélectionner au moins une catégorie d'abonnés contenant des utilisateurs.",
      },
    },
    search_notification: {
      error: {
        title: 'Une erreur est survenue lors de la recherche',
        message: "Veuillez réessayer ultérieurement ou contacter l'assistance.",
      },
    },
  },
  infrastructures: {
    create_category: {
      success: {
        title: 'Catégorie ajoutée.',
        message: 'La catégorie a bien été ajoutée.',
      },
      error: {
        title: 'Une erreur est survenue',
        message: 'Veuillez vérifier le contenu de la catégorie',
      },
    },
    create_infrastructure: {
      success: {
        title: 'Infrastructure ajoutée.',
        message: 'L’infrastructure a bien été ajoutée.',
      },
      error: {
        title: 'Une erreur est survenue',
        message: 'Veuillez vérifier le contenu de la page',
      },
    },
    edit_infrastructure: {
      success: {
        title: 'Infrastructure modifiée.',
        message: 'L’infrastructure a bien été modifiée.',
      },
      error: {
        title: 'Une erreur est survenue',
        message: "L'infrastructure n'a pas pu être modifiée.",
      },
    },
    add_infrastructures: {
      success: {
        title: 'Infrastructure ajoutée. | Infrastructures ajoutées.',
        message: "L'infrastructure a bien été ajoutée. | Les infrastructures ont bien été ajoutées.",
      },
      error: {
        title: 'Une erreur est survenue',
        message: "L'infrastructure n'a pas pu être ajoutée. | Les infrastructures n'ont pas pu être ajoutées",
      },
    },
    import_infrastructure_csv: {
      errorTypeInfra: {
        message: "Veuillez vérifier le type/catégorie d'infrastructures importé.",
      },
    },
    search_infrastructures: {
      error: {
        title: 'Une erreur est survenue lors de la recherche',
        message: "Veuillez réessayer ultérieurement ou contacter l'assistance.",
      },
    },
    get_infrastructures: {
      error: {
        title: 'Une erreur est survenue lors de la récupération des infrastructures',
        message: "Veuillez réessayer ultérieurement ou contacter l'assistance.",
      },
    },
    get_infrastructure_google: {
      error: {
        title: "Une erreur est survenue lors de la récupération de l'infrastructure",
        message: 'Erreur',
      },
    },
    delete_infrastructure: {
      success: {
        title: 'Infrastructure supprimée. | Infrastructures supprimées.',
        message: "L'infrastructure a bien été supprimée. | Les infrastructures ont bien été supprimées.",
      },
      error: {
        title: 'Une erreur est survenue',
        message: "L'infrastructure n'a pas pu être supprimée. | Les infrastructures n'ont pas été correctement supprimées.",
      },
    },
  },
  garbage: {
    collections: {
      collectionCreate: {
        success: {
          title: 'Collecte enregistrée',
          message: "La collecte a bien été enregistrée et est visible par les citoyens sur l'application.",
        },
        error: {
          title: 'Impossible de créer la collecte',
          message: "Une erreur est survenue, la collecte n'a pas été créée.",
        },
      },
      collectionEdit: {
        success: {
          title: 'Collecte modifiée',
          message: "La collecte a bien été modifiée et est visible par les citoyens sur l'application.",
        },
        error: {
          title: 'Impossible de modifier la collecte',
          message: "Une erreur est survenue, la collecte n'a pas été modifiée.",
        },
      },
      collectionDelete: {
        success: {
          title: 'Collecte supprimée',
          message: "La collecte a bien été supprimée et n'est plus visible sur l'application.",
        },
        error: {
          title: 'Impossible de supprimer la collecte',
          message: "Une erreur est survenue, la collecte n'a pas été supprimée.",
        },
      },
      collectionDateEdit: {
        success: {
          title: 'Date de collecte modifiée',
          message: "La date de collecte a bien été modifiée et est visible sur l'application.",
        },
        error: {
          title: 'Impossible de modifier la date de collecte',
          message: "Une erreur est survenue, la date de collecte n'a pas été modifiée.",
        },
      },
      collectionDateDelete: {
        success: {
          title: 'Date de collecte supprimée',
          message: "La date de collecte a bien été supprimée et n'est plus visible sur l'application.",
        },
        error: {
          title: 'Impossible de supprimer la date de collecte',
          message: "Une erreur est survenue, la date de collecte n'a pas été supprimée.",
        },
      },
    },
    sortingGuide: {
      saveSortingGuide: {
        success: {
          title: 'Guide du tri modifié',
          message: 'Le guide du tri a bien été modifié.',
        },
        error: {
          title: "Impossible d'enregistrer le guide du tri",
          message: 'Veuillez vérifier le contenu de la page.',
        },
      },
    },
    slots: {
      delete_slot: {
        success: {
          title: 'Créneau supprimé.',
          message: 'Le créneau a bien été supprimé.',
        },
        error: {
          title: "Le créneau n'a pas pu être supprimé.",
          message: "Veuillez réessayer ultérieurement ou contacter l'assistance.",
        },
      },
      modify_slot: {
        success: {
          title: 'Créneau modifié.',
          message: 'Le créneau a bien été modifié.',
        },
        error: {
          title: "Le créneau n'a pas pu être modifié",
          message: "Veuillez réessayer ultérieurement ou contacter l'assistance.",
        },
      },
      add_slot: {
        success: {
          title: 'Créneau ajouté.',
          message: 'Le créneau a bien été ajouté.',
        },
        error: {
          title: "Le créneau n'a pas pu être ajouté",
          message: "Veuillez réessayer ultérieurement ou contacter l'assistance.",
        },
      },
      get_template_slots: {
        error: {
          title: 'Impossible de récupérer les modèles',
          message: "Veuillez réessayer ultérieurement ou contacter l'assistance.",
        },
      },
      fill_previous_slot: {
        error: {
          title: 'Erreur',
          message: "Veuillez remplir le dernier créneau avant d'en créer un nouveau.",
        },
      },
      delete_date: {
        error: {
          title: 'Impossible de supprimer la date {publishDate}',
          message: "Veuillez réessayer ultérieurement ou contacter l'assistance.",
        },
      },
      get_slots_dates: {
        error: {
          title: 'Erreur, Impossible de retrouver les dates associées au créneau',
          message: "Veuillez réessayer ultérieurement ou contacter l'assistance.",
        },
      },
    },
    garbageTypes: {
      createType: {
        success: {
          title: 'Type de déchets ajouté',
          message: 'Le type de déchets a bien été ajouté.',
        },
        error: {
          title: 'Impossible de créer le type de déchets',
          message: "Une erreur est survenue, le type de déchets n'a pas été créé.",
        },
      },
      editType: {
        success: {
          title: 'Type de déchets modifié',
          message: 'Le type de déchets a bien été modifié.',
        },
        error: {
          title: 'Impossible de modifier le type de déchets',
          message: "Une erreur est survenue, le type de déchets n'a pas été modifié.",
        },
      },
      deleteType: {
        success: {
          title: 'Type de déchets supprimé',
          message: 'Le type de déchets a bien été supprimé.',
        },
        error: {
          title: 'Impossible de supprimer le type de déchets',
          message: "Une erreur est survenue, le type de déchets n'a pas été supprimé.",
        },
      },
      patchColor: {
        success: {
          title: 'Couleur modifiée',
          message: 'La couleur a bien été modifiée.',
        },
        error: {
          title: 'Impossible de modifier la couleur',
          message: "Une erreur est survenue, la couleur n'a pas été modifiée.",
        },
      },
      patchIcon: {
        success: {
          title: 'Icône modifiée',
          message: "L'icône a bien été modifiée.",
        },
        error: {
          title: "Impossible de modifier l'icône",
          message: "Une erreur est survenue, l'icône n'a pas été modifiée.",
        },
      },
    },
  },
  zones: {
    common: {
      error: {
        title: 'Une erreur est survenue',
        message: 'Veuillez vérifier le contenu de la page',
      },
    },
    createZone: {
      success: {
        title: 'Zone ajoutée',
        message: 'La zone a bien été ajoutée.',
      },
      error: {
        title: 'Impossible de créer la zone',
        message: "Une erreur est survenue, la zone n'a pas été créée.",
      },
    },
    editZone: {
      success: {
        title: 'Zone modifiée',
        message: 'La zone a bien été modifiée.',
      },
      error: {
        title: 'Impossible de modifier la zone',
        message: "Une erreur est survenue, la zone n'a pas été modifiée.",
      },
    },
    patchColor: {
      success: {
        title: 'Couleur modifiée',
        message: 'La couleur de la zone a bien été modifiée.',
      },
      error: {
        title: 'Impossible de modifier la couleur de la zone',
        message: "Une erreur est survenue, la couleur n'a pas été modifiée.",
      },
    },
    deleteZone: {
      success: {
        title: 'Zone supprimée',
        message: 'La zone a bien été supprimée.',
      },
      error: {
        title: 'Impossible de supprimer la zone',
        message: "Une erreur est survenue, la zone n'a pas été supprimée.",
      },
    },
    zonesOverlap: {
      error: {
        title: 'Les zones ne peuvent pas se chevaucher',
        message: "Veuillez modifier votre zone pour qu'elle ne chevauche pas une autre zone.",
      },
    },
  },
  citiesManagement: {
    createCity: {
      error: {
        title: 'Impossible de créer la ville',
        message: "Une erreur est survenue, la ville n'a pas été créée.",
      },
    },
    editCity: {
      error: {
        title: 'Impossible de modifier la ville',
        message: "Une erreur est survenue, la ville n'a pas été modifiée.",
      },
    },
    deleteCity: {
      error: {
        title: 'Impossible de supprimer la ville',
        message: "Une erreur est survenue, la ville n'a pas été supprimée.",
      },
    },
    getCities: {
      error: {
        title: 'Impossible de récupérer les villes',
        message: "Une erreur est survenue, les villes n'ont pas pu être chargées.",
      },
    },
    getCity: {
      error: {
        title: 'Impossible de récupérer la ville',
        message: "Une erreur est survenue, la ville n'a pas pu être chargée.",
      },
    },
    saveCities: {
      success: {
        title: 'Villes enregistrées',
        message: 'Les modifications ont bien été enregistrées.',
      },
      error: {
        title: 'Impossible de sauvegarder les villes',
        message: "Les modifications n'ont pas pu être enregistrées.",
      },
    },
  },
};
