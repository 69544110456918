<template>
  <Menu as="div" class="relative inline-block w-full text-left" :class="{ 'cursor-not-allowed': props.context.disabled }">
    <div>
      <MenuButton
        v-slot="{ open }"
        class="relative flex h-10 w-full items-center focus:outline-none"
        :class="{ 'pointer-events-none': props.context.disabled }"
        @click="focusSearchInput"
      >
        <div
          class="flex w-full items-center rounded-sm bg-neutral-light p-2 pr-10 text-left text-neutral-dark transition ease-in-out"
          :class="{
            '!text-neutral-silver': !props.context._value,
            'pointer-events-none !bg-neutral-cloud !text-neutral-silver': props.context.disabled,
            '!bg-white ring-1 ring-base-basic ': !props.context.ghost && open,
            '!bg-transparent': props.context.ghost,
          }"
        >
          <!-- Prefix Icon -->
          <Icon
            v-if="props.context.prefixIcon && props.context.prefixIconSource === 'tabler'"
            :id="props.context.prefixIcon"
            class="mr-2"
            :class="{ 'text-neutral-silver': props.context.disabled, '!text-neutral-silver': !props.context._value }"
            size="large"
            :stroke="2"
            aria-hidden="true"
          />

          <NeocityIcon
            v-if="props.context.prefixIcon && props.context.prefixIconSource === 'neocity'"
            class="mr-2 h-5 w-5"
            :icon="props.context.prefixIcon"
            :clearBackground="true"
            aria-hidden="true"
          />
          <!-- End Prefix Icon -->

          <!-- Current Icon -->
          <Icon
            v-if="props.context._value && getOptionIconSource(props.context._value) === 'tabler' && getOptionIconId(props.context._value)"
            :id="getOptionIconId(props.context._value)"
            size="large"
          />

          <NeocityIcon
            v-if="props.context._value && getOptionIconSource(props.context._value) === 'neocity' && getOptionIconId(props.context._value)"
            class="mr-2 h-5 w-5"
            :icon="getOptionIconId(props.context._value)"
            :clearBackground="true"
            aria-hidden="true"
          />
          <!-- End Current Icon -->

          <!-- Current Value -->
          <div v-if="!props.context.iconOnly" class="w-full">
            <Tag
              v-if="props.context._value && props.context.tags && getColorValue()"
              :color="getColorValue()"
              :size="props.context.tagSize || 'large'"
              >{{ getHumanValue() }}</Tag
            >

            <div
              v-else
              class="block w-fit max-w-full overflow-hidden text-ellipsis whitespace-nowrap text-neutral-silver"
              :class="[
                {
                  '!text-neutral-silver': props.context.disabled,
                  '!text-neutral-dark': props.context._value,
                },
                props.context.valueClasses,
              ]"
            >
              {{ getHumanValue() }}
            </div>
          </div>
          <!-- End Current Value -->

          <!-- Chevron -->
          <Icon
            :id="open ? 'chevron-up' : 'chevron-down'"
            class="absolute right-2.5 top-[50%] translate-y-[-50%] text-neutral-dark"
            :class="{ 'text-neutral-silver': props.context.disabled }"
            aria-hidden="true"
          />
          <!-- End Chevron -->
        </div>
      </MenuButton>
    </div>

    <MenuItems class="absolute right-0 z-30 mt-2 w-auto min-w-full origin-top-right divide-y rounded-sm bg-white shadow-lg focus:outline-none">
      <div class="p-2">
        <div v-if="props.context.toggleSearch" ref="searchInput" class="mb-2">
          <FormKit
            v-model="searchValue"
            type="search"
            :placeholder="props.context.searchPlaceholder"
            suffixIcon="search"
            wrapperClass="search-icon"
            @keydown.space="$event.stopPropagation()"
            @keydown.enter="onSelect(filteredOptions[0])"
            @keydown.tab="onSelect(filteredOptions[0])"
          />
        </div>
        <div
          ref="searchResultContainer"
          :class="[
            props.context.dropdownHeight ? 'max-h-' + props.context.dropdownHeight : 'max-h-[200px]',
            props.context.dropdownClasses,
            { 'overflow-y-scroll': filteredOptions && filteredOptions.length },
          ]"
        >
          <template v-if="filteredOptions && filteredOptions.length">
            <MenuItem v-for="option in filteredOptions" :key="option" v-slot="{ active }" class="my-2" @click="onSelect(option)">
              <button
                :class="[
                  option.value === props.context._value ? '!bg-base-basic text-white' : 'text-neutral-dark',
                  'group !my-1 flex w-full items-center rounded-sm p-2 text-sm',
                  active ? 'bg-neutral-light' : '',
                  props.context.iconOnly ? 'flex justify-center' : '',
                  props.context.buttonItemClasses,
                ]"
              >
                <!-- Icon -->
                <NeocityIcon
                  v-if="option.value && getOptionIconSource(option.value) === 'neocity' && getOptionIconId(option.value)"
                  class="mr-2 h-5 w-5"
                  :class="{ 'text-white': option.value === props.context._value }"
                  :icon="getOptionIconId(option.value)"
                  :clearBackground="true"
                  aria-hidden="true"
                />

                <Icon
                  v-if="option.value && getOptionIconSource(option.value) === 'tabler' && getOptionIconId(option.value)"
                  :id="getOptionIconId(option.value)"
                  size="large"
                ></Icon>
                <!-- End Icon -->

                <!-- Value -->
                <div v-if="!props.context.iconOnly">
                  <span v-if="props.context.tags" class="flex w-full items-center justify-between">
                    <Tag :color="option.attrs.color" size="medium">{{ option.label || option }}</Tag>
                  </span>
                  <div v-else class="text-left" :class="option.attrs?.classes">{{ option.label || option }}</div>
                </div>
                <!-- End Value -->
              </button>
            </MenuItem>
          </template>
          <span v-else class="p-2 text-sm text-neutral-dark">{{ props.context.emptyText || $t('forms.empty.noResults') }}</span>
        </div>

        <div v-if="props.context.buttonProps" class="mt-2">
          <Button
            :type="props.context.buttonProps.type"
            :icon="props.context.buttonProps.icon"
            class="w-full"
            @click="props.context.buttonProps.click()"
          >
            {{ props.context.buttonProps.label }}
          </Button>
        </div>
      </div>
    </MenuItems>
  </Menu>
</template>

<script setup lang="ts">
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue';
import { ref, computed } from 'vue';

import { useI18n } from 'vue-i18n';
import { SelectOption } from '@/models/formkit.model';

const { t } = useI18n();

const props = defineProps({
  context: {
    type: Object,
    required: true,
  },
});

const searchInput = ref();
const searchValue = ref('');
const searchResultContainer = ref();
const focusSearchInput = (): void => {
  if (!props.context.toggleSearch) return;
  searchValue.value = '';
  setTimeout(() => {
    const input = searchInput.value.querySelector('input');
    if (input) input.focus();
  }, 300);
};

const filteredOptions = computed(() => {
  if (!props.context.options) return [];
  if (!searchValue.value) return props.context.options;
  searchResultContainer.value.scrollTo(0, 0);
  return props.context.options.filter((option: SelectOption | string) => {
    if (typeof option === 'string') return option.toLowerCase().includes(searchValue.value.toLowerCase());
    if (option.label === undefined) return false;
    return (
      option.label.toLowerCase().replace(/\s/g, '').includes(searchValue.value.toLowerCase().replace(/\s/g, '')) ||
      (option.attrs
        ? Object.values(option.attrs).some((attr: string) =>
            attr.toLowerCase().replace(/\s/g, '').includes(searchValue.value.toLowerCase().replace(/\s/g, ''))
          )
        : false)
    );
  });
});

const onSelect = (option: SelectOption): void => {
  if (option) props.context.node.input(option.value);
};

const getOptionIconId = (value: string): string => {
  if (!value) return '';
  const currentOption = props.context.options.find((option: SelectOption) => option.value === value);
  if (currentOption && currentOption.attrs) return currentOption?.attrs.icon;
  return '';
};

const getOptionIconSource = (value: string): string => {
  if (!value) return '';
  const currentOption = props.context.options.find((option: SelectOption) => option.value === value);
  if (currentOption && currentOption.attrs) return currentOption?.attrs.iconSource;
  return '';
};

const getHumanValue = (): string => {
  /* eslint-disable no-underscore-dangle */
  if (props.context._value && props.context._value.label) return props.context._value.label;

  if (props.context.options) {
    const selectedOption = props.context.options.find((option: SelectOption) => option.value === props.context._value);
    if (selectedOption && selectedOption.label) return selectedOption.label;
  }

  return props.context.placeholder || t('forms.placeholder.defaultSelect');
};

const getColorValue = (): string => {
  const selectedOption = props.context.options.filter((option: SelectOption) => option.value === props.context._value)[0];
  return selectedOption && selectedOption.attrs ? selectedOption.attrs.color : '';
};
</script>
