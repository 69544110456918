import { createInput } from '@formkit/vue';
import formKitTheme from '@/formkit/formKitTheme';
import { addAsteriskPlugin, checkboxTooltip } from '@/formkit/formKitPlugins';
import { createAutoAnimatePlugin } from '@formkit/addons';
import { fr } from '@formkit/i18n';

import Dropdown from '@/components/atoms/Dropdown.vue';
import AutoComplete from '@/components/molecules/forms/AutoComplete.vue';
import FileUpload from '@/components/molecules/forms/FileUpload.vue';
import ColorPicker from '@/components/molecules/forms/ColorPicker.vue';
import NumberWithUnit from '@/components/molecules/forms/NumberWithUnit.vue';
import IconPicker from '@/components/molecules/forms/IconPicker.vue';
import SwitchInput from '@/components/molecules/forms/SwitchInput.vue';
import DatePicker from '@/components/molecules/forms/DatePicker.vue';
import { checkbox } from '@/formkit/inputs/checkbox';

const formkitConfig = {
  locales: { 'fr-FR': fr },
  locale: 'fr-FR',
  config: formKitTheme,
  plugins: [addAsteriskPlugin, checkboxTooltip, createAutoAnimatePlugin()],
  inputs: {
    checkbox,
    dropdown: createInput(Dropdown, {
      props: [
        'placeholder',
        'options',
        'component',
        'tags',
        'title',
        'toggleSearch',
        'searchPlaceholder',
        'buttonProps',
        'ghost',
        'emptyText',
        'prefixIcon',
        'prefixIconSource',
        'valueClasses',
        'iconOnly',
        'tagSize',
        'dropdownHeight',
        'buttonItemClasses',
        'dropdownClasses',
      ],
    }),
    autocomplete: createInput(AutoComplete, {
      props: ['label1Path', 'label2Path', 'label3Path', 'findMethod', 'inputType', 'placeholder', 'emptyText', 'value'],
    }),
    colorPicker: createInput(ColorPicker),
    numberWithUnit: createInput(NumberWithUnit, {
      props: ['unit'],
    }),
    iconPicker: createInput(IconPicker, {
      props: ['iconOnly'],
    }),
    fileUpload: createInput(FileUpload, {
      props: ['display', 'title', 'uploadRoute', 'returnFile', 'returnUrl', 'limit', 'formats'],
    }),
    switchInput: createInput(SwitchInput, {
      props: ['onText', 'offText'],
    }),
    datePicker: createInput(DatePicker, {
      props: ['format', 'minDate', 'maxDate', 'enableTimePicker', 'multiCalendars', 'position', 'autoApply', 'textInput', 'clearable'],
    }),
  },
};

export default formkitConfig;
