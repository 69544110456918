import { defineStore } from 'pinia';

import { useApps } from '@/stores/apps';
import { useCities } from '@/stores/cities';
import { useGarbage } from '@/stores/garbage';
import { useGoogle } from '@/stores/google';
import { useNotifications } from '@/stores/notifications';
import { useReports } from '@/stores/reports';
import { useSurveys } from '@/stores/surveys';
import { useUser } from '@/stores/user';

import { axiosNeocityInstance } from '@/api/axios';
import { i18nt } from '@/locales';
import * as Sentry from '@sentry/vue';

import { Client, ClientMenuItem } from '@/models/client.model';

interface State {
  client: Client | null;
  clients: Client[] | null;
  lastUpdate: number;
}

export const useClient = defineStore({
  id: 'client',
  state: (): State => ({
    client: null,
    clients: null,
    lastUpdate: 0,
  }),
  getters: {
    clientId(): string | undefined {
      const userStore = useUser();
      return userStore.$state.clientId;
    },
  },
  actions: {
    async getClient(id = this.clientId): Promise<Client> {
      const userStore = useUser();
      const appStore = useApps();
      const citiesStore = useCities();
      return new Promise((resolve, reject) => {
        axiosNeocityInstance
          .get(`/client/${id}`)
          .then((response) => {
            if (!response.data) reject();
            this.resetClientState();
            this.client = response.data;
            userStore.setClientId(response.data.id);
            Sentry.setTag('clientId', response.data.id);
            this.lastUpdate = Date.now();
            resolve(response.data);
          })
          .then(async () => {
            if (this.isAnEPCI()) await citiesStore.getCities();
            const appId = this.client?.apps[0]?.id;
            if (this.client.status === 'client' && appId) await appStore.getApp(appId);
          })
          .catch((error) => {
            Sentry.captureException(error);
            reject(error);
          });
      });
    },

    async patchClient(fields: Partial<Client>): Promise<Client> {
      return new Promise((resolve, reject) => {
        axiosNeocityInstance
          .patch(`/client/${this.clientId}`, fields)
          .then((response) => {
            this.client = response.data;
            resolve(response.data);
          })
          .catch((error) => {
            Sentry.captureException(error);
            reject(error);
          });
      });
    },

    async getClients(): Promise<Client[]> {
      return new Promise((resolve, reject) => {
        axiosNeocityInstance
          .get('/client', {
            params: {
              filter: {
                order: 'name ASC',
                fields: {
                  id: true,
                  name: true,
                  code: true,
                },
              },
            },
          })
          .then((response) => {
            this.clients = response.data;
            resolve(response.data);
          })
          .catch((error) => {
            Sentry.captureException(error);
            reject(error);
          });
      });
    },

    // Reset all current client data in the stores
    resetClientState(): void {
      const appStore = useApps();
      const surveyStore = useSurveys();
      const notificationStore = useNotifications();
      const reportStore = useReports();
      const googleStore = useGoogle();
      const garbageStore = useGarbage();
      const citiesStore = useCities();
      this.client = null;
      appStore.$reset();
      surveyStore.$reset();
      notificationStore.$reset();
      reportStore.$reset();
      googleStore.$reset();
      garbageStore.$reset();
      citiesStore.$reset();
    },

    hasFeature(featureId: string): boolean {
      const clientFeatures = this.client?.features;
      if (clientFeatures && clientFeatures[featureId] && clientFeatures[featureId].enabled) return clientFeatures[featureId].enabled;
      return false;
    },

    isAnEPCI(): boolean {
      return this.client?.epci?.enabled;
    },

    flattenMenu(items: ClientMenuItem[] | undefined, domain: string): { label: string; value: string }[] {
      if (!items) return [];
      const flattenedMenu: { label: string; value: string }[] = [];
      const flatten = (menuItem: ClientMenuItem, parentLabel = '', parentUrl = ''): void => {
        const url = `${parentUrl}/${menuItem.id}`;
        const label = parentLabel ? `${parentLabel} → ${menuItem.name}` : menuItem.name;
        flattenedMenu.push({ label, value: `${domain}${url}` });
        if (menuItem.children) menuItem.children.forEach((child) => flatten(child, label, url));
      };
      items.forEach((menuItem: ClientMenuItem) => flatten(menuItem, '', ''));
      flattenedMenu.push({ label: i18nt('routes.preferences'), value: `${domain}/direct/preferences` });
      return flattenedMenu;
    },

    findMenuItemById(id: string): ClientMenuItem | undefined {
      const find = (items: ClientMenuItem[] | undefined): ClientMenuItem | undefined => {
        if (!items) return;
        // eslint-disable-next-line consistent-return
        return items.find((item) => {
          if (item.id === id) return true;
          if (item.children) return find(item.children);
          return false;
        });
      };
      return find(this.client?.menu);
    },
  },
  persist: true,
});
