export default {
  send_notification: 'Send a notification',
  blocks: {
    messageTitle: 'Your message',
    categoriesTitle: 'To whom is this notification addressed',
    categoriesText: 'Subscribers to the selected categories will receive the notification',
    targetTitle: 'Target page',
    targetText: 'Choose the destination page on click on the notification',
    sendingDateTitle: 'Sending date',
    sendingDateText: 'Send now or schedule your sending',
  },
  tooltips: {
    categories:
      'The audience for the selected categories is displayed on the right hand side of the screen. This is the number of users subscribed to the selected categories',
    target: 'You can select an existing page from the types offered or add an external link.',
    date: 'You can choose to send the notification now, schedule it for a later date or set a recurrence.',
    estimation: 'You will reach up to {estimation} users of your application with the settings you have chosen!',
    restrictedCategory: 'This category is restricted. Only users of the "{profiles}" profile(s) will receive the notification.',
    openingRate: 'This data only takes into account clicks on the notification banner. Openings from the app notification center are not counted.',
  },
  forms: {
    placeholder: {
      search: 'Search ...',
      notificationTitle: 'Notification title',
      notificationMessage: 'Notification content',
      news: 'News list',
      agenda: 'Events list',
      page: 'Sections list',
      sendingDate: 'Sending date',
      sendingHour: 'Sending hour',
      send: 'Send',
      sendEvery: 'Send every',
      at: 'At',
      from: 'From',
      until: 'Until',
      lastReccurence: 'Last repetition',
      searchPage: 'Search a page',
      searchNews: 'Search a news',
      searchEvent: 'Search an event',
    },
    label: {
      notificationTitle: 'Title',
      notificationMessage: 'Message',
      createdBy: 'Created by',
      news: 'Select a news',
      agenda: 'Select an event',
      page: 'Select a section',
      external: 'Enter the page address',
      notificationSent: 'Notifications sent',
      notificationOpened: 'Open notifications',
      notificationOpeningRate: 'Opening rate',
      customisedFrequency: 'Customised frequency',
      selectedDates: 'Selected dates',
      categories: 'Categories',
      profiles: 'Profiles',
      audienceEstimation: 'Audience estimation',
    },
    errors: {
      titleRequired: 'You must enter a title',
      messageRequired: 'You must enter a message',
      categoriesRequired: 'You must select at least one category of subscribers',
      targetRequired: 'The selected category does not contain any subscribers | The selected categories do not contain any subscribers',
      emptyDays: 'You must select at least one day',
    },
    empty: {
      news: 'No news were found',
      events: 'No events were found',
      pages: 'No pages were found',
      searchResults: 'No notifications were found for this search',
    },
  },
  table: {
    columns: {
      status: 'Status',
      date: 'Date & Time',
      message: 'Message',
      categories: 'Categories',
      target: 'Target Page',
      sent: 'Sent',
      opened: 'Opened',
    },
    tooltips: {
      defaultReccurrent: 'This notification is part of a group of recurring notifications.',
    },
  },
  targets: {
    home: 'Home',
    actu: 'News',
    agenda: 'Event',
    page: 'Page',
    external: 'External link',
  },
  sendingModes: {
    now: 'Now',
    later: 'Later on',
    recurrent: 'Recurrent',
  },
  scheduleModes: {
    days: 'Every day',
    weeks: 'Every week',
    custom: 'Custom',
  },
  sendingInfo: {
    laterOn: 'The notification will be sent on {date} at {time}.',
    recurrent: {
      days: 'The subscribers will receive the notification every day at {time} from {startDate} to {endDate}.',
      weeks: 'The subscribers will receive the notification every week on {days} at {time} from {startDate} to {endDate}.',
      custom: 'The subscribers will receive the notification at {time} on the following dates:',
    },
    editDateTime: "You can edit the notification's content and the sending date and time by duplicating it and deleting the original notification.",
  },
  status: {
    cancelled: 'Cancelled',
    pending: 'Scheduled',
    sent: 'Sent',
    sending: "En cours d'envoi",
  },
  actions: {
    duplicate: 'Duplicate the notification',
    display: 'Display the notification on the application',
    hide: 'Hide the notification on the application',
    delete: 'Delete the notification',
    cancel: "Cancel the notification's sending",
    edit: 'Edit the notification',
    send: 'Send the notification',
    save: 'Save the modifications',
    schedule: 'Schedule the notification',
  },
  modals: {
    delete_notification: {
      title: 'Delete the notification',
      text: 'Do you want to delete the notification *{title}* ?',
      warning: "This action is irreversible, the notification will be removed from your users' application and from your administration interface.",
    },
    cancel_notification: {
      title: 'Cancel the notification',
      text: 'Do you want to cancel the notification *{title}* ?',
      warning: 'This action is irreversible, the notification will not be sent to your users.',
    },
    recurrentGroup: {
      delete: {
        title: 'Delete a recurrent notification',
        text: 'Are you sure you want to delete this notification ? This notification is part of a group of recurring notifications.',
        options: {
          self: 'Delete this notification only',
          all: 'Delete all the notifications in the group',
        },
      },
      cancel: {
        title: 'Cancel a recurrent notification',
        text: "You're about to cancel a notification that is part of a group of recurring notifications",
        options: {
          self: 'Cancel this notification only',
          all: 'Cancel all the notifications in the group',
        },
      },
      edit: {
        title: 'Edit a recurrent notification',
        text: "You're about to edit a notification that is part of a group of recurring notifications",
        options: {
          self: 'Edit this notification only',
          all: 'Edit all the notifications in the group',
        },
      },
    },
  },
  toasts: {
    list: {
      text: 'Discover the <a href="https://neocitybacko.s3.amazonaws.com/neocity/Guide%20des%20bonnes%20pratiques%20Notifications%20Push.pdf" target="_blank" class="underline">Neocity notification guide</a>: a multitude of tips for writing and programming your notifications to inform and alert your users at the best time',
    },
    featureDisabled: {
      title: "This feature isn't available for your city. Please contact your Neocity administrator.",
    },
    notificationSent: {
      title: 'Notification sent the : {date} at {hour}',
    },
    notificationCancelled: {
      title: 'Notification cancelled the : {date} at {hour}',
    },
  },
  empty: {
    past: 'No past notifications.',
    inComing: 'No incoming notifications.',
    no_results: 'No notifications found.',
    no_results_with_filters: 'No notifications found with these filters.',
    no_selected_dates: 'No dates selected',
  },
};
