import { defineStore } from 'pinia';

import type { City } from '@/models/permissions.model';
import * as Sentry from '@sentry/vue';
import { useUser } from '@/stores/user';
import { axiosNeocityInstanceV3 } from '@/api/axios';

interface State {
  currentCityId: string | null;
  cities: City[] | null;
}

export const useCities = defineStore({
  id: 'cities',
  state: (): State => ({
    cities: null,
    currentCityId: null,
  }),
  getters: {
    clientId(): string | undefined {
      const userStore = useUser();
      return userStore.$state.clientId;
    },
  },
  actions: {
    getCities(): Promise<City[]> {
      return new Promise((resolve, reject) => {
        axiosNeocityInstanceV3
          .get(`/clients/${this.clientId}/cities`)
          .then((response) => {
            if (!response.data) return;
            this.cities = response.data;
            this.cities = this.cities.sort((a: City, b: City) => {
              if (a.isMain) return -1;
              if (b.isMain) return 1;
              return a.name.localeCompare(b.name);
            });
            this.currentCityId = this.cities.filter((city: City) => city.isMain)[0]?._id;
            resolve(response.data);
          })
          .catch((error) => {
            Sentry.captureException(error);
            reject(error);
          });
      });
    },
    getCity(cityId: string): Promise<City[]> {
      return new Promise((resolve, reject) => {
        axiosNeocityInstanceV3
          .get(`/clients/${this.clientId}/cities/${cityId}`)
          .then((response) => {
            if (!response.data) return;
            resolve(response.data);
          })
          .catch((error) => {
            Sentry.captureException(error);
            reject(error);
          });
      });
    },
    createCity(city: City): Promise<City> {
      return new Promise((resolve, reject) => {
        axiosNeocityInstanceV3
          .post(`/clients/${this.clientId}/cities`, city)
          .then((response) => {
            if (!response.data) return;
            resolve(response.data);
          })
          .catch((error) => {
            Sentry.captureException(error);
            reject(error);
          });
      });
    },
    patchCity(cityId: string, fields: Partial<City>): Promise<City | undefined> {
      return new Promise((resolve, reject) => {
        axiosNeocityInstanceV3
          .patch(`/clients/${this.clientId}/cities/${cityId}`, fields)
          .then((response) => {
            if (!response.data) return;
            resolve(response.data);
          })
          .catch((error) => {
            Sentry.captureException(error);
            reject(error);
          });
      });
    },
    deleteCity(cityId: string): Promise<void> {
      return new Promise((resolve, reject) => {
        axiosNeocityInstanceV3
          .delete(`/clients/${this.clientId}/cities/${cityId}`)
          .then(() => {
            resolve();
          })
          .catch((error) => {
            Sentry.captureException(error);
            reject(error);
          });
      });
    },
  },
  persist: true,
});
