export default {
  navigation: {
    myAccount: 'My account',
    myInformation: 'Profile',
    preferences: 'Preferences',
    notifications: 'Notifications',
    plan: 'Plan',
    rolesAndUsers: 'Roles and users',
    users: 'Users',
    rolesManagement: 'Roles management',
    citiesManagement: 'Cities management',
    appManagement: 'Application management',
    tilesManagement: 'Tiles management',
    tree: 'Tree structure',
    stores: 'Stores',
    legal: 'Legal',
    tutorials: 'Tutorials and guides',
  },
  citiesManagement: {
    toast: 'Find all the cities in your area.<br> You can rename them, customize their labels or create new ones.',
    titleTerritory: 'My area',
    titleCities: 'My municipalities',
    forms: {
      label: {
        name: 'Name',
        color: 'Color',
      },
      placeholder: {
        name: 'City',
        color: 'Choose a color',
      },
      errors: {
        name: 'Please enter a valid city name',
        colorRequired: 'Please select a color',
        nameAlreadyExists: 'This city name is already in use. Please enter a unique name.',
      },
    },
    addCity: 'Add a city',
    deleteCityModal: {
      title: 'Delete a city',
      warningTitle: 'Are you sure you want to get rid of this city?',
      warningText: 'The deletion is irreversible. Users of this city will no longer have access to the administration interface.',
    },
    empty: {
      noCity: 'No city at the moment.',
    },
  },
};
